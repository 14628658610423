.interrupt-modal {
    padding: 1em;

    & .modal-title {
        font-size: 20px;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
    }
    & .modal-body {
        margin: 2em 1em;
    }
    & .footer {
        margin: 0;
        padding: 0;
        text-align: center;
    }
    & .footer .close-modal-button {
        display: inline-block;
        border: 1px solid #888;
        border-radius: 4px;
        background: #eee;
        padding: 0.5em 2em;
        cursor: pointer;
    }
    & .footer .close-modal-button:hover {
        background: #fff;
    }
    & a.close-modal,
    & a.close-modal:visited {
        background-color: initial;
        text-decoration: none;
        border: none;
    }
}
