.sms-form {
    & .number-input,
    & .code-input {
        padding: 15px;
    }

    & .number-input > div,
    & .code-input > div {
        padding: 0.7rem 0;
    }
    & input[name=number],
    & input[name=code] {
        height: 2rem;
        border:solid 1px #ccc;
        border-radius: 5px;
        width: 100%;
        padding: 0.2rem 0.5rem;
        /* why cascading not work?? */
        box-sizing: border-box;
    }
    & .button-container .submit-btn,
    & button.close-modal {
        font-size: 1rem;
        border-style: solid;
        border-radius: 5px;
        width: 16rem;
        margin-bottom:0.5rem;
        padding: 4px 10px;
    }

    & button.close-modal{
        background: #FFF;
        border-color: rgb(190, 190, 190);
    }

    & .submit-btn.primary {
        background-color: #5bc0de;
        border-color: #46b8da;
        color: #FFF;
    }

    & .submit-btn.secondary {
        background-color: #5cb85c;
        border-color: #4cae4c;
        color: #FFF;
    }

    & .number-input>p,
    & .code-input>p,
    & .footer>p {
        color: #333322;
        font-size: 0.8rem
    }

    & .button-container {
        text-align: center;
    }
    & h3.modal-title {
        background-color: #4f5a60;
        background-image: none;
        border-radius: 8px 8px 0 0 ;
        border-style: none;
        color: #fff;
        margin: 0;
        font-size: 1rem;
        font-weight: normal;
        padding: 0.5rem 0.8rem;
        text-decoration: none;
    }
    & .footer {
        padding: 0.7rem;
    }
    & a:link,
    & a:visited,
    & a:hover  {
        border: none;
        color: #0D75B5;
        text-decoration: none;
    }
    & .footer>.message {
        text-align: center;
        padding: 1rem 0;
    }

    & p.errorMsg {
        color: #a94442;
        background-color: #f2dede;
        padding: 0.3rem;
        border-radius: 5px;
    }
}

// モーダルのアニメーション
.sms-auth-modal-overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    position: fixed;
    inset: 0;
}

.sms-auth-modal-overlay--after-open{
    opacity: 1;
}

.sms-auth-modal-overlay--before-close{
    opacity: 0;
}
